<template>
  <b-row>
    <!-- commercial_name -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.legal_name"
        label="Legal Name"
        :rules="[validations.maxValueRequired(info.commercial_name, 100)]"
        counter="100"
        outlined
        required
        dense
      />
    </b-col>

    <!-- commercial_name -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.commercial_name"
        label="Commercial Name"
        :rules="[validations.maxValueRequired(info.commercial_name, 255)]"
        counter="255"
        required
        outlined
        dense
      />
    </b-col>

    <!-- ADDRESS -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <VueGooglePlaces
        v-model="info.address"
        :api-key="key"
        types="address"
        @placechanged="getAddressData"
        class="without-placeholder"
      >
        <v-text-field
          v-model="info.address"
          label="Address"
          :rules="[validations.address(info.address, info.addressComponent)]"
          required
          outlined
          dense
        />
      </VueGooglePlaces>
    </b-col>

    <!-- VAT ID / Company Registration / ID -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.vat"
        label="VAT ID / Company Registration / ID"
        :rules="validations.required"
        required
        outlined
        dense
      />
    </b-col>

    <!-- PHONE -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        type="number"
        v-model="info.contract_phone"
        :rules="validations.required"
        label="Phone"
        required
        outlined
        dense
      />
    </b-col>

    <!-- EMAIL -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.contract_email"
        :rules="validations.emailRequired"
        label="School Email"
        required
        outlined
        dense
      />
    </b-col>

    <!-- WEBSITE -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.websiteUrl"
        :rules="validations.required"
        label="Website"
        required
        outlined
        dense
      />
    </b-col>

    <!-- TITLE 1 -->
    <b-col lg="12" md="12" sm="12" cols="12">
      <strong> Legal representative </strong>
      <br />
      <i>
        The legal representative is the person who will receive an email with
        the instructions to complete the Ynsitu registration.
      </i>
    </b-col>

    <!-- NAME -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.contract_name"
        :rules="[validations.maxValueRequired(info.contract_name, 100)]"
        counter="100"
        label="Name"
        required
        outlined
        dense
      />
    </b-col>

    <!-- Last Name -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.contract_surname"
        :rules="[validations.maxValueRequired(info.contract_surname, 100)]"
        counter="100"
        label="Last Name"
        outlined
        required
        dense
      />
    </b-col>

    <!-- POSITION -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.contract_position"
        :rules="[validations.maxValueRequired(info.contract_position, 100)]"
        counter="100"
        label="Position"
        required
        outlined
        dense
      />
    </b-col>

    <!-- Email -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.contract_passport"
        :rules="validations.emailRequired"
        label="Email"
        required
        outlined
        dense
      />
    </b-col>

    <!-- TITLE 2 -->
    <b-col lg="12" md="12" sm="12" cols="12">
      <strong>
        {{ $t("SIGNUP.ACCOUNT_ADMINISTRATOR") }}
      </strong>
      <br />
      <i>
        {{ $t("SIGNUP.DESCRIPTION_ACCOUNT_ADMINISTRATOR") }}
      </i>
    </b-col>

    <!-- NAME -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.user_name"
        :rules="[validations.maxValueRequired(info.user_name, 100)]"
        counter="100"
        label="Name"
        required
        outlined
        dense
      />
    </b-col>

    <!-- Last Name -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.user_surname"
        :rules="[validations.maxValueRequired(info.user_surname, 100)]"
        counter="100"
        label="Last Name"
        required
        outlined
        dense
      />
    </b-col>

    <!-- POSITION -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.user_position"
        :rules="[validations.maxValueRequired(info.user_position, 100)]"
        counter="100"
        label="Position"
        required
        outlined
        dense
      />
    </b-col>

    <!-- Email -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.user_passport"
        :rules="validations.emailRequired"
        label="Email"
        required
        outlined
        dense
      />
    </b-col>
  </b-row>
</template>
<script>
import Rules from "@/core/services/utils/validations";
import { VueGooglePlaces } from "vue-google-places";
export default {
  components: {
    VueGooglePlaces,
  },
  data() {
    return {
      form: {
        legal_name: null,
        commercial_name: null,
        address: null,
        addressComponent: null,
        vat: null,
        contract_phone: null,
        contract_email: null,
        websiteUrl: null,
        contract_name: null,
        contract_surname: null,
        contract_position: null,
        contract_passport: null,
        user_name: null,
        user_surname: null,
        user_position: null,
        user_passport: null,
      },
      key: process.env.VUE_APP_PLACE_KEY,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * get info google place for addressComponent
     * @method getAddressData
     * @returns 'addressComponent' object whit data
     */
    getAddressData(addressData) {
      this.form.addressComponent = addressData;
    },
  },
  computed: {
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function () {
        const data = {
          ...this.form,
        };
        this.$emit("update:legal-info", data);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scope>
.v-text-field__slot {
  .v-label::after {
    content: "*";
    color: red;
    margin-left: 2px;
  }
}
</style>
