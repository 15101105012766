<template>
  <div class="padding-top-60">
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        Already have an account?
      </span>
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'login' }"
      >
        Sign In!
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signup-->
    <div class="login-form login-signin w-100 pb-5">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Sign Up</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your details to create your account
        </p>
      </div>

      <!-- FORM LODGING -->
      <v-form ref="form" v-model="valid" lazy-validation>
        <b-row>
          <!-- Provider Type -->
          <b-col lg="12" md="12" sm="12" cols="12">
            <strong>
              Provider type
            </strong>
            <v-radio-group
              v-model="schoolCompanyTypeId"
              column
              :rules="validations.required"
            >
              <v-radio
                label="School (courses and optionally accommodation)"
                :value="1"
              />
              <v-radio label="Professional hosting provider" :value="2" />
              <!--<v-radio
                label="Private hosting provider (Host family)"
                :value="3"
              />
              <v-radio
                label="Private teacher (I offer classes and accommodation at my home)"
                :value="4"
              />-->
            </v-radio-group>
          </b-col>

          <!-- Person Type -->
          <b-col lg="12" md="12" sm="12" cols="12">
            <strong>
              Person Type
            </strong>
            <v-radio-group
              v-model="bussinessPrivate"
              row
              :rules="validations.required"
            >
              <v-radio label="Legal" :value="1" />
              <v-radio label="Private" :value="2" />
            </v-radio-group>
          </b-col>
        </b-row>

        <LegalForm
          :legal-info.sync="legalInformation"
          v-if="bussinessPrivate === 1"
        />
        <PrivateForm
          :private-info.sync="privateInformation"
          :providerType="schoolCompanyTypeId"
          v-if="bussinessPrivate === 2"
        />

        <Recaptcha :sitekey="key" :callback="onVerify" />

        <v-btn
          depressed
          color="primary"
          class="float-right"
          :loading="invite_member"
          @click="onSubmit"
          :disabled="!robot"
        >
          {{ $t("GENERAL.SEND") }}
        </v-btn>
      </v-form>
    </div>
    <!--end::Signup-->
  </div>
</template>

<script>
import { REGISTER_COMPANY } from "@/core/services/store/general/company.module";
import Rules from "@/core/services/utils/validations";
import LegalForm from "./component/LegalForm";
import PrivateForm from "./component/PrivateForm";
import Recaptcha from "./component/recaptcha";

export default {
  components: {
    LegalForm,
    PrivateForm,
    Recaptcha,
  },
  data() {
    return {
      valid: true,
      invite_member: false,
      schoolCompanyTypeId: null,
      bussinessPrivate: null,
      legalInformation: null,
      privateInformation: null,
      robot: false,
      key: null,
      // VALIDATIONS
      validations: {
        valid: true,
        ...Rules,
      },
      SCHOOL: 1,
      PROFESSIONAL_HOSTING: 2,
    };
  },
  methods: {
    /**
     * Create new user
     * @method onSubmit
     */
    onSubmit() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.invite_member = true;

        let data = {
          schoolCompanyTypeId: this.schoolCompanyTypeId,
          bussinessPrivate: this.bussinessPrivate,
        };

        if (this.bussinessPrivate === 1) {
          data = {
            ...data,
            ...this.legalInformation,
          };
        } else {
          if (
            parseInt(this.schoolCompanyTypeId) !== this.SCHOOL &&
            parseInt(this.schoolCompanyTypeId) !== this.PROFESSIONAL_HOSTING
          ) {
            this.privateInformation.commercial_name = `${this.privateInformation.user_name} ${this.privateInformation.user_surname}`;
          }
          data = {
            ...data,
            ...this.privateInformation,
          };
        }

        this.$store.dispatch(REGISTER_COMPANY, data).then(data => {
          if (data) {
            this.invite_member = false;
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$router.push("/success");
            }, 2000);
          } else {
            this.invite_member = false;
          }
        });
      }
    },

    onVerify: function(response) {
      if (response) this.robot = true;
    },
  },
  mounted() {
    this.key = process.env.VUE_APP_CATPCHA_KEY;
  },
};
</script>

<style lang="scss">
.padding-top-60 {
  padding-top: 60px;
}
</style>
