<template>
  <b-row>
    <!-- Commercial name -->
    <b-col
      lg="6"
      md="6"
      sm="12"
      cols="12"
      v-if="
        parseInt(providerType) === SCHOOL ||
          parseInt(providerType) === PROFESSIONAL_HOSTING
      "
    >
      <v-text-field
        v-model="info.commercial_name"
        label="Commercial Name"
        :rules="validations.required"
        required
        outlined
        dense
      />
    </b-col>

    <!-- VAT ID / Company Registration / ID -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.vat"
        label="VAT ID / Company Registration / ID"
        :rules="validations.required"
        required
        outlined
        dense
      />
    </b-col>

    <!-- PHONE -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        type="number"
        v-model="info.contract_phone"
        :rules="validations.required"
        label="Phone"
        required
        outlined
        dense
      />
    </b-col>

    <!-- WEBSITE -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.websiteUrl"
        :rules="validations.required"
        label="Website"
        required
        outlined
        dense
      />
    </b-col>

    <!-- TITLE 2 -->
    <b-col lg="12" md="12" sm="12" cols="12">
      <strong>
        {{ $t("SIGNUP.ACCOUNT_ADMINISTRATOR") }}
      </strong>
      <br />
      <i>
        {{ $t("SIGNUP.DESCRIPTION_ACCOUNT_ADMINISTRATOR") }}
      </i>
    </b-col>

    <!-- NAME -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.user_name"
        :rules="[validations.maxValueRequired(info.user_name, 100)]"
        counter="100"
        label="Name"
        required
        outlined
        dense
      />
    </b-col>

    <!-- Last Name -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.user_surname"
        :rules="[validations.maxValueRequired(info.user_surname, 100)]"
        counter="100"
        label="Last Name"
        required
        outlined
        dense
      />
    </b-col>

    <!-- Email -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <v-text-field
        v-model="info.user_passport"
        :rules="validations.emailRequired"
        label="Email"
        required
        outlined
        dense
      />
    </b-col>

    <!-- ADDRESS -->
    <b-col lg="6" md="6" sm="12" cols="12">
      <VueGooglePlaces
        v-model="info.address"
        :api-key="key"
        types="address"
        @placechanged="getAddressData"
        class="without-placeholder"
      >
        <v-text-field
          v-model="info.address"
          label="Address"
          :rules="[validations.address(info.address, info.addressComponent)]"
          required
          outlined
          dense
        />
      </VueGooglePlaces>
    </b-col>
  </b-row>
</template>
<script>
import Rules from "@/core/services/utils/validations";
import { VueGooglePlaces } from "vue-google-places";
export default {
  components: {
    VueGooglePlaces,
  },
  props: {
    providerType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      key: process.env.VUE_APP_PLACE_KEY,
      form: {
        commercial_name: null,
        address: null,
        addressComponent: null,
        user_name: null,
        user_surname: null,
        user_passport: null,
        vat: null,
        contract_phone: null,
        websiteUrl: null,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
      SCHOOL: 1,
      PROFESSIONAL_HOSTING: 2,
    };
  },
  methods: {
    /**
     * get info google place for addressComponent
     * @method getAddressData
     * @returns 'addressComponent' object whit data
     */
    getAddressData(addressData) {
      this.form.addressComponent = addressData;
    },
  },
  computed: {
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:private-info", data);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scope>
.v-text-field__slot {
  .v-label::after {
    content: "*";
    color: red;
    margin-left: 2px;
  }
}
</style>
